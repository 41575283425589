.Card {
    position: relative;
    border: 3px solid var(--textbox-outline-color);
    border-radius: 8px;
    width: 255px;
    text-align: center;
    padding: 10px 0;
    margin: 30px 0;

    display: grid;
    grid-template-rows: 70px 50px auto;
    row-gap: 10px;
    background-color: #fefdfd;
}
  
.Card img {
    margin: auto;
}
  
.Card h4 {
    margin-top: 20px;
    align-self: center;
}

.Card ul {
    list-style-type: none;
    grid-template-rows: repeat(8, 30px);
    margin: auto 0;
    display: grid;
}