/*
--------------------------------
-- FIXED ELEMENTS SECTION ------
--------------------------------
*/
/* .scrollToTopBtn {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  background-color: var(--accent-color);
  padding: 5px;
  border-radius: 5px;
  z-index: 800;
}

.scrollToTopBtn:hover {
  border: 0;
  background-color: var(--btn-hover-color);
}

.scrollToTopBtn ion-icon {
  height: 40px;
  width: 40px;
}

.fadeIn {
  opacity: 1;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.fadeOut {
  opacity: 0;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
} */


/*
--------------------------------
-------- LAYOUT ---
--------------------------------
*/

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 50px;
  justify-items: center;
  margin: 0 120px;
}

/*
--------------------------------
-------- TECHNOLOGY SECTION ---
--------------------------------
*/

/*
----- CARD BASE ------
*/
/* .cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}  */

/* .cardBase {
  position: relative;
  border: 3px solid var(--textbox-outline-color);
  border-radius: 8px;
  width: 255px;
  height: 330px;
  text-align: center;
  padding: 10px 0;
  margin: 30px 0;
}

.cardBase img {
  margin: auto;
}

.cardBase h4 {
  margin-top: 20px;
  align-self: center;
} */


/*
----- TECH CARDS ------
*/
.techCard {
  display: grid;
  grid-template-rows: 70px 50px auto;
  row-gap: 10px;
  background-color: #fefdfd;
  /* margin: 30px 53px; */
}

/*TEMPORARY - TODO: REPLACE WITH IMG*/
.techCard h1 {
  font-size: 300%;
}

.techCard ul {
  list-style-type: none;
  grid-template-rows: 30px 30px 30px;
  margin: auto 0;
  display: grid;
}

/*
--------------------------------
-------- POJECTS SECTION ---
--------------------------------
*/

/* .projectCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  width: 100%;
} */
/* 
.projectCard {
  background-color: #f3f3f3;
  padding: 0;
  width: 300px;
  height: 400px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 140px 1fr;
  align-items: center;
  justify-content: start;
  margin: 30px;
} */

/* .cardHeader {
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 3px solid var(--textbox-outline-color);
  background-color: var(--textbox-bg-color);
  color: #212529;
  top:0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
} */

/* .cardHeader h4 {
  z-index: 0;
  padding-bottom: 9px;
} */

/* .projectCardBG {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
} */
/* 
.projectCardBG img {
  height: 100%;
  position: absolute;
} */

/* .projectCardText {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 50px;

  position: absolute;
  font-size: 150%;
  color: white;
  z-index: 1;
  text-align: center;
  width: 100%;
}

.projectCardText a {
  color: var(--btn-hover-color) !important;
  width: auto;
  margin: 0 auto;
  cursor: pointer;
}

.shadowBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--shadow-color);
  z-index: 1;
} */

/*
--------------------------------
-------- MODAL SECTION ---
--------------------------------
*/

.modalShadow {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--shadow-color);
  z-index: 900;
}

.modal {
  position: absolute;
  max-width: 750px;
  min-width: 350px;
  width: 100%;
  height: 714px;
  background-color: var(--textbox-bg-color);
  z-index: 900;
  transform: translate(0, -20px);
  border: 4px solid var(--textbox-outline-color);
  border-radius: 5px;
}

.modalContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.carouselImgContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: black;
}

.carouselSlideBtn {
  position: absolute;
  top:0;
  left: 0;
  width: calc(100%);
  height: calc(100% - 15%); /* 15% is the height of the arrow elements in the modal */
  border: none;
  background-color: transparent;
}

.carouselImg {
  max-height: 397px;
}

.magnifyImg {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--shadow-color);
  z-index: 999;
}

.magnifyImg img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
}

.closeBtn {
  position: absolute;
  font-size: 170%;
  color: var(--textbox-outline-color);
  cursor: pointer;
  top: 3px;
  right: 7px;
  z-index: 900;
  border: 0;
  padding: 3px;
}
.closeBtn:hover {
  color: var(--btn-hover-color);
  border: 0;
}


a.closeBtn:not([href]):not([tabindex]):hover {
  border: 0;
  color: var(--accent-color);
  transition: color .2s;
}

/*
--------------------------------
-------- CONTACT SECTION ---
--------------------------------
*/

/* .contactForm {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contactForm input, 
.contactForm textarea {
  outline: none;
  margin-bottom: 3px;
  resize: vertical;
}

.contactForm input[type=submit] {
  margin-left: auto;
} */

/*
--------------------------------
-------- FOOTER SECTION ---
--------------------------------
*/

.socialLink {
  width: 50px;
  height: 50px;
  transition: color .3s;
}

.socialLink:hover {
  border: none;
  color: var(--accent-color);
  transition: color .3s;
}

.socialLink ion-icon {
  width: 100%;
  height: 100%;
}

/*
--------------------------------
-------- MEDIA QUERIES ---
--------------------------------
*/

/*Large Size iPads*/
@media (max-width: 1024px) {
  .modal {
    width: 80%;
  }
}
/*Medium Size*/
@media (max-width: 768px) {


  .projectCards {
    grid-template-columns: 1fr;
  }
  

  /*
  --------------------------------
  -------- NAVBAR QUERIES ---
  --------------------------------
  */
  .navbar {
    background-color: white;
  }

  a.homeBtn {
    width: 60px;
  }

  .navbarMobileBtn {
    margin: auto 0;
  }

  .pageTitle {
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
  }

  .pageTitle div {
    justify-items: center;
  }

  /*
  --------------------------------
  -------- CONTACT FORM QUERIES ---
  --------------------------------
  */
  .contactForm textarea {
    width: 50%;
  }

    /*
  --------------------------------
  -------- GENERAL SETUP QUERIES ---
  --------------------------------
  */
  .container {
    margin: 0px;
  }

     /*
  --------------------------------
  -------- PROJECTS SECTION QUERIES ---
  --------------------------------
  */

  .modal {
    height: 614px;
  }
  .carouselImg {
    height: 345px;
  }
  
}

/*Small Size*/
@media (max-width: 375px) {
  .modal {
    height: 580px;
    transform: translate(0,0);
  }
}