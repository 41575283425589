.Button {
    border: none;
    font-size: 120%;
    color: var(--btn-text-color);
    background-color: var(--accent-color);
    width: auto;
    height: auto;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}

.default {
    color: var(--btn-text-color);
    background-color: var(--accent-color);
}

.Button:hover,
.Button:hover {
  background-color: var(--btn-hover-color);
}