.ContactForm {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.Container {
    max-width: 600px;
    width: 100%;
    padding: 10px;
}
.ContactForm input, 
.ContactForm textarea {
    outline: none;
    margin-bottom: 3px;
    resize: vertical;
    width: 100%;
}

.ContactForm input[type=submit] {
    margin-left: auto;
}

.Container .ContactForm .button {
    width: auto;
}

.button {
    border: none;
    font-size: 120%;
    color: var(--btn-text-color);
    background-color: var(--accent-color);
    width: auto;
    height: auto;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}

.default {
    color: var(--btn-text-color);
    background-color: var(--accent-color);
}

.button:hover,
.button:hover {
  background-color: var(--btn-hover-color);
}