.ProjectCards {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  grid-gap: 10px;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  width: 100%;
  /* max-width: 1240px; */
}

@media only screen and (max-width: 1110px) {
  .ProjectCards {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

/* .projectCard {
    background-color: #f3f3f3;
    padding: 0;
    width: 300px;
    height: 400px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 140px 1fr;
    align-items: center;
    justify-content: start;
    margin: 30px; 
  } */
