/* .SplashScreen {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: auto calc(100vh - auto); */
    /* position: relative;
    height: 100vh;
    width: 100%;
    min-height: 600px;
  
    background: url(../../assets/header-bg.png) no-repeat center;
    background-size: cover;
} */

.SplashScreen {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;

    flex-wrap: wrap-reverse;

    height: 100vh;
    width: 100%;
    min-height: 600px;
    background: url(../../assets/header-bg.png) no-repeat center;
    background-size: cover;
}