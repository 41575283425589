.Navbar {
    width: 100%;
    height: 100%;
}

.homeBtn {
    margin: auto 0;
    border: none;
    cursor: pointer;
    width: 80px;
    height: auto;
}

.homeBtn:hover {
    border: none;
    cursor: pointer;
}
  
.homeBtn img {
    width: 100%;
    height: 100%;
}

.navbar {
    width: 100%;
    font-size: 100%;
    background-color: none;
    transition: background-color .3s;
}

.navlinks {
    display: flex;
    width: 100%;
    list-style-type: none;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-size: 130%;
}

.navbarMobileBtn:hover {
    background: none;
}
  
.stickyNavbar {
    position: fixed;
    width: 100%;
    z-index: 900; 
    background-color: white;
    transition: background-color .3s;
}