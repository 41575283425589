.projectCard {
    background-color: #f3f3f3;
    padding: 0;
    width: 250px;
    height: 300px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
    align-items: center;
    justify-content: start;
    margin: 30px;
  }
  
  .cardHeader {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 3px solid var(--textbox-outline-color);
    background-color: var(--textbox-bg-color);
    color: #212529;
    top:0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    padding: 5px;
  }
  
  .cardHeader h4 {
    z-index: 0;
    padding: 0;
  }
  
  .projectCardBG {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .projectCardBG img {
    height: 100%;
    position: absolute;
  }
  
  .projectCardText {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  
    position: absolute;
    font-size: 150%;
    color: white;
    z-index: 1;
    text-align: center;
    width: 100%;
  }
  
  .projectCardText a {
    color: var(--btn-hover-color) !important;
    width: auto;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .shadowBox {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--shadow-color);
    z-index: 1;
  }

  .cardBase {
    position: relative;
    border: 3px solid var(--textbox-outline-color);
    border-radius: 8px;
    text-align: center;
  }
  