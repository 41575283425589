.InfoBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 0px 10px;
    min-height: 230px;
    height: 60%;
}

.InfoBox h5 {
    color: #858484;
}

.InfoBox hr {
    background-color: #858484;
}

.textArea {
    width: 100%;
    height: 100%;
    resize: none;
    overflow: scroll;
}
